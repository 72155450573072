import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import hospital2p from '../../Images/hospital2.png'





export default function Hospital2() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> ABHA Hospital , Abha , KSA  ​</h3>
                <p> Project description: Hospital Building  </p>
                <p>Type of contract: Design </p>
                <p> Area:  35000 m2  </p>
                <p>Type of works : Architectural coordination's and Electromechanical </p>
            </div>
            <div className="col-md-6 text-center">
                <img src={hospital2p} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

        </div>

    </>
  )
}
