import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import urban12p from '../../Images/urban12.jpg'
import urban12p1 from '../../Images/urban12.1.jpg'
import urban12p2 from '../../Images/urban12.2.jpg'




export default function urban12() {
  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`container  `}>
        
          <div className="container my-5 py-5 ">
              <div className="row align-items-center">
                <div className="col-md-6">
                    <h3 className={`${styles.color} my-3`}> 10th Of Ramdan Housing Project ​</h3>
                    <p> Project description: planning  </p>
                    <p>Type of contract: Design  </p>
                    <p>Type of works : Master Planning Detailed Planning and Presentaion </p>
                </div>
                <div className="col-md-6">
                    <img src={urban12p} alt="achegy" className='img-fluid'/>
                </div>   
              </div>

          </div>
          <div className="row my-5">
      
      <div className="col-md-6 my-1 text-center">
        <img src={urban12p1} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
      </div>
      <div className="col-md-6 my-1 text-center">
        <img src={urban12p2} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
      </div>
     
    </div>
       </div>


    </section>

    
    </>
   
  )
}
