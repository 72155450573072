import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import waleed1 from '../../Images/waleed1.jpg'
import waleed2 from '../../Images/waleed2.jpg'
import waleed3 from '../../Images/waleed3.jpg'






export default function Info() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}> Waleed Azzam Office  ​</h3>
                <p> Project description: Office    </p>
                <p>Type of contract: : Design and supervision  </p>
                <p> Area:  150 m2  </p>
                <p>Owner Name : Eng. / Waleed Azzam </p>
                <p>Type of works : : Architectural , Electromechanical and Decorations   </p>
            </div>
            <div className="col-md-5">
                <img src={waleed1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-6 my-1 text-center">
               <img src={waleed2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={waleed3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
           
        
          
          </div>
        </div>

    </>
  )
}
