import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import warehouse1 from '../../Images/warehouse1.jpg'
import warehouse2 from '../../Images/warehouse2.jpg'
import warehouse3 from '../../Images/warehouse3.jpg'






export default function Warehouse() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}>  Warehouse for Travco group company  ​</h3>
                <p> Project description: warehouse and store    </p>
                <p>Type of contract: : Design and supervision  </p>
                <p> Area:  :  2500 m2  </p>
                <p>Owner Name : Travco Group    </p>
                <p>Type of works : : Architectural and Electromechanical  </p>
            </div>
            <div className="col-md-5">
                <img src={warehouse1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-6 my-1 text-center">
               <img src={warehouse2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={warehouse3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
          </div>
        </div>

    </>
  )
}
