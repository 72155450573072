import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import escap1 from '../../Images/escap1.jpg'
import escap2 from '../../Images/escap2.jpg'
import escap3 from '../../Images/escap3.jpg'
import escap4 from '../../Images/escap4.jpg'
import escap5 from '../../Images/escap5.jpg'
import escap6 from '../../Images/escap6.jpg'
import escap7 from '../../Images/escap7.jpg'


export default function Escap() {
  return (
   <>
           <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Ecap  vacsera  </h3>
                <p> Project description: electromechanical work </p>
                <p>Type of contract: design and supervision  </p>
                <p> Area:  10000 m2 </p>
                <p>Owner Name : Ecap  vacsera </p>
                <p>Type of works : : electromechanical design</p>
            </div>
            <div className="col-md-6">
                <img src={escap1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={escap2} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={escap3} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={escap4} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={escap5} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={escap6} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={escap7} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>


          </div>
        </div>
   
   </>
  )
}
