import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import urban4p from '../../Images/urban4.jpg'
import urban4p1 from '../../Images/urban4.1.png'
import urban4p2 from '../../Images/urban4.2.png'



export default function urban4() {
  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`container  `}>
        
          <div className="container my-5 py-5 ">
              <div className="row align-items-center">
                <div className="col-md-7">
                    <h3 className={`${styles.color} my-3`}> Maghagha City Planning ​</h3>
                    <p> Project description: planning  </p>
                    <p>Type of contract: Design  </p>
                    <p>Owner Name : Maghagha city planning   </p>
                    <p>Type of works : Master Planning Detailed Planning  </p>
                </div>
                <div className="col-md-5">
                    <img src={urban4p} alt="achegy" className='img-fluid'/>
                </div>   
              </div>
              <div className="row my-5">
      
            <div className="col-md-6 my-1 text-center">
               <img src={urban4p1} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={urban4p2} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
        
           
          
          </div>
          </div>
   
       </div>


    </section>

    
    </>
   
  )
}
