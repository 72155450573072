import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import river1 from '../../Images/River1.jpg'
import river2 from '../../Images/River2.jpg'






export default function RiverClub() {
  return (

    <>
        <div className="container my-5 py-5  ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Diplomat River Club  ​</h3>
                <p> Project description: Development   </p>
                <p>Type of contract: Design and supervision  </p>
                <p> Area:  100 m2  </p>
                <p> Owner Name : The Ministry of Foreign Affairs </p>
                <p>Type of works : Decoration  </p>
            </div>
            <div className="col-md-6">
                <img src={river2} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row ">
          <div className="col-md-12 my-1 text-center">
               <img src={river1} alt="achegy" className={`img-fluid`}/> 
            </div>
         
           
         
        
          
          </div>

        </div>

    </>
  )
}
