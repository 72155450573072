import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import cargos1 from '../../Images/cargos1.jpg'
import cargos2 from '../../Images/cargos2.jpg'
import cargos3 from '../../Images/cargos3.jpg'
import cargos4 from '../../Images/cargos4.jpg'
import cargos5 from '../../Images/cargos5.jpg'
import cargos6 from '../../Images/cargos6.jpg'
import cargos7 from '../../Images/cargos7.jpg'





export default function Cargos() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Port Said for Containers & Cargos Company​</h3>
                <p> Project description: Development for revenue billing floor   </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  250 m2  </p>
                <p>Type of works : : Architectural , civil and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={cargos7} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={cargos1} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={cargos2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={cargos3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={cargos4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={cargos5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={cargos6} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
        
          
          </div>
        </div>

    </>
  )
}
