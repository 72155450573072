import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import mina from '../../Images/mina.png'





export default function Mina() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}> Mina Ramada Hotel , KSA  ​</h3>
                <p> Project description: Hotel Building  </p>
                <p>Type of contract: Design </p>
                <p> Area:  52000 m2  </p>
                <p>Type of works : Architectural coordination's and Electromechanical </p>
            </div>
            <div className="col-md-5 text-center">
                <img src={mina} alt="achegy" className='img-fluid'/>
            </div>   
          </div> 
 
        </div>

    </>
  ) 
}
