import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import asia from '../../Images/asia.jpg'





export default function Asia() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-8">
                <h3 className={`${styles.color} my-3`}> Asia Towers (Two Residential Towers one hotel and one office tower)  ​</h3>
                <p> Project description: Residential and office Building    </p>
                <p>Type of contract: Design </p>
                <p> Area:  80000 m2  </p>
                <p>Type of works : Architectural coordination's and Electromechanical </p>
            </div>
            <div className="col-md-4 text-center">
                <img src={asia} alt="achegy" className='img-fluid'/>
            </div>   
          </div> 

        </div>

    </>
  )
}
