import React from 'react'
import styles from '../Proj3Folder/Proj3.module.css'
import serviceBuilding1 from '../../Images/serviceBuilding1.jpg'
import serviceBuilding2 from '../../Images/serviceBuilding2.jpg'
import serviceBuilding3 from '../../Images/serviceBuilding3.jpg'

export default function ServiceBuilding() {
  return (
  <>
                <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}>Service building​</h3>
                <p> Project description: building for services    </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  350 m2 </p>
                <p>Owner Name : travco Group  </p>
                <p>Type of works : architectural and structure design</p>
            </div>
            <div className="col-md-6">
                <img src={serviceBuilding1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5">
          <div className="col-md-6 my-1 text-center">
               <img src={serviceBuilding2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={serviceBuilding3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>

    
          </div> 
        </div>
  
  </>
  )
}
