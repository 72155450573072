import React from 'react'
import AOS from "aos";
import 'aos/dist/aos.css'
import foodstore from '../../Images/food.jpg'
import interlock1 from '../../Images/interlock1.jpg'
import centralKitchen1 from '../../Images/centralKitchen1.jpg'
import serviceBuilding1 from '../../Images/serviceBuilding1.jpg'
import workshop2 from '../../Images/workshop2.jpg'
import storage1 from '../../Images/storage1.jpg'
import styles from '../Proj3Folder/Proj3.module.css'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function TravcoGroup() {

    useEffect(() => {
        AOS.init({duration: 1500})
      
      }, [])
  return (
  <>
         <section className='my-5 py-3 '>
       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Travco Group Buildings
        
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
        <div className="row">

        <div className="col-md-4 my-3 text-center" data-aos='fade-up'> 
              <Link to="/foodstore" className={`${styles.linkPro}`}> 
            <img src={foodstore} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Food Store </p>
             </Link> 
          </div>   

        <div className="col-md-4 my-3 text-center" data-aos='fade-up'>    
         <Link to="/interlock" className={`${styles.linkPro}`}> 
         <img src={interlock1} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'>Interlock industries</p>
         </Link>  
          </div>

          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>    
         <Link to="/centralkitchen" className={`${styles.linkPro}`}> 
         <img src={centralKitchen1} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'>Central Kitchen</p>
         </Link>  
          </div>
          
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>    
         <Link to="/servicebuilding" className={`${styles.linkPro}`}> 
         <img src={serviceBuilding1} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'>Service building</p>
         </Link>  
          </div>

          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>    
         <Link to="/workshop" className={`${styles.linkPro}`}> 
         <img src={workshop2} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'>Work Shop</p>
         </Link>  
          </div>

          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>    
         <Link to="/storage" className={`${styles.linkPro}`}> 
         <img src={storage1} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'>Storage</p>
         </Link>  
          </div>

        </div>
       </div>
       
    </section>
  
  </>
  )
}
