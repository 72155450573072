import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import urban3p from '../../Images/urban3.jpg'
import urban3p1 from '../../Images/urban3.1.jpg'
import urban3p2 from '../../Images/urban3.2.jpg'
import urban3p4 from '../../Images/urban3.4.jpg'



export default function urban3() {
  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`container `}>
        
          <div className="container my-5 py-5 ">
              <div className="row align-items-center">
                <div className="col-md-7">
                    <h3 className={`${styles.color} my-3`}> El-Fayoum Unversity ​</h3>
                    <p> Project description: This project included planning and development of the campus of cairo unversity fayoum branch  </p>
                    <p>Type of contract: Design and supervision </p>
                    <p> Area:  14000 m2  </p>
                    <p>Owner Name : Ministry of High Education - El fayoum unversity  </p>
                    <p>Type of works : Master Planning Detailed Planning Landscape Infra-structures </p>
                </div>
                <div className="col-md-5">
                    <img src={urban3p1} alt="achegy" className='img-fluid'/>
                </div>   
              </div>
              <div className="row my-5">
          <div className="col-md-4 my-1 text-center">
               <img src={urban3p} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban3p2} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban3p4} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
           
           
          
          </div>
          </div>
   
       </div>


    </section>

    
    </>
   
  )
}
