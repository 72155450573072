import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import urban5p from '../../Images/urban5.jpg'
import urban5p1 from '../../Images/urban5.1.jpg'



export default function urban5() {
  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`container  `}>
        
          <div className="container my-5 py-5 ">
              <div className="row align-items-center">
                <div className="col-md-6">
                    <h3 className={`${styles.color} my-3`}> Compound Heliopolis Hills ​</h3>
                    <p> Project description: planning  </p>
                    <p>Type of contract: Design  </p>
                    <p>Type of works : Master Planning Detailed Planning and Presentaion </p>
                </div>
                <div className="col-md-6">
                    <img src={urban5p} alt="achegy" className='img-fluid'/>
                </div>   
              </div>
              <div className="row my-5">
      
              <div className="col-md-12 my-1 text-center">
                <img src={urban5p1} alt="achegy" className={`img-fluid`}/> 
              </div>
              
  
     
    
    </div>
          </div>
   
       </div>


    </section>

    
    </>
   
  )
}
