import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'jquery/dist/jquery.min.js'
import { HashRouter } from 'react-router-dom';






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <HashRouter>
    <App />
    </HashRouter>
 
);


reportWebVitals();
