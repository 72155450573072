import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import arak from '../../Images/arak.jpg'





export default function Arak() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}> Arak Makah Hotel , Makah, Kingdom of Saudi Arabia (KSA) Lusail  ​</h3>
                <p> Project description: Hotel Building  </p>
                <p>Type of contract: Design </p>
                <p> Area:  36000 m2  </p>
                <p>Type of works : Architectural coordination's and Electromechanical </p>
            </div>
            <div className="col-md-5 text-center">
                <img src={arak} alt="achegy" className='img-fluid'/>
            </div>   
          </div>  
 
        </div>

    </>
  ) 
}
