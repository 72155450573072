import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import warehouse from '../../Images/warehouse1.jpg'
import cargos3 from '../../Images/cargos3.jpg'
import crystal from '../../Images/crystal.jpg'
import info from '../../Images/info1.jpg'
import waleed from '../../Images/waleed1.jpg'
import airport from '../../Images/airport1.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'




export default function Office() {
    useEffect(() => {
        AOS.init({duration: 1500})
      
      }, [])
  return (

    <>
       <section className='my-5 py-3 '>
     

      
       
       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Office & Commercial Buildings
  
     
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
        <div className="row">


    <div className="col-md-4 my-3 text-center" data-aos='fade-up'>    
        <Link to="/airport" className={`${styles.linkPro}`}> 
        <img src={airport} alt="achegy" className={`${styles.imgSizeProj}`}/>
        <p className='text-center fw-bold mt-3 '>VIP lounge of Cairo airport </p>
        </Link>  
    </div>
    <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
        <Link to="/cargos" className={`${styles.linkPro}`}> 
        <img src={cargos3} alt="achegy" className={`${styles.imgSizeProj}`}/>
        <p className='text-center fw-bold mt-3 '> Port Said for Containers & Cargos Company </p>
        </Link>  
    </div>
    <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
        <Link to="/waleed" className={`${styles.linkPro}`}> 
        <img src={waleed} alt="achegy" className={`${styles.imgSizeProj}`}/>
        <p className='text-center fw-bold mt-3 '> Waleed Azzam Office  </p>
        </Link>  
    </div>
  
    <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
        <Link to="/info" className={`${styles.linkPro}`}> 
        <img src={info} alt="achegy" className={`${styles.imgSizeProj}`}/>
        <p className='text-center fw-bold mt-3 '>Information Center building  </p>
        </Link>  
    </div>
    <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
        <Link to="/crystal" className={`${styles.linkPro}`}> 
        <img src={crystal} alt="achegy" className={`${styles.imgSizeProj}`}/>
        <p className='text-center fw-bold mt-3 '>Cristal Tower   </p>
        </Link>  
    </div>
    <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
        <Link to="/warehouse" className={`${styles.linkPro}`}> 
        <img src={warehouse} alt="achegy" className={`${styles.imgSizeProj}`}/>
        <p className='text-center fw-bold mt-3 '> Warehouse for Travco group company  </p>
        </Link>  
    </div>
 
  </div>
       </div>

  

    </section>

    
    </>
   
  )
}
