import React from 'react';
import homeVid from '../Images/homeVid.mp4'
import styles from '../HomeFolder/Home.module.css'

const Player = () => {
    return (
        <div className={`position-relative mt-3`}>
            <video width="100%" height="100%" playsInline autoPlay muted loop >
               <source src={homeVid} type="video/mp4" />
            </video>
            <div>
              <i className={`${styles.movingArrow} fa-solid fa-chevron-down fs-1`}></i>
            </div>
             
        </div>
    )
}

export default Player;
