import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import thwra from '../../Images/thwra.jpg'
import thwra1 from '../../Images/thwra1.jpg'
import thwra2 from '../../Images/thwra2.jpg'
import thwra3 from '../../Images/thwra3.jpg'
import thwra4 from '../../Images/thwra4.jpg'
import thwra5 from '../../Images/thwra5.jpg'
import thwra6 from '../../Images/thwra6.jpg'






export default function NBEthawra() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> National Bank of Egypt (Al-Ahly)– Al Thwra Branch (V.I.P )  ​</h3>
                <p> Project description: Development Bank building and reconditioning   </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  1000 m2  </p>
                <p>Owner Name : National Bank Of Egypt (Al-Ahly) </p>
                <p>Type of works : Decoration and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={thwra} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={thwra1} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={thwra2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={thwra3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={thwra4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={thwra5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={thwra6} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
           
         
        
          
          </div>

        </div>

    </>
  )
}
