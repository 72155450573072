import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import ahram1 from '../../Images/ahram1.jpg'
import ahram2 from '../../Images/ahram2.jpg'






export default function AhramClub() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}> Al Ahram Club ​</h3>
                <p> Project description: : Educational Building     </p>
                <p>Type of contract: Design and supervision  </p>
                <p> Area:  4000 m2  </p>
                <p> Owner Name : TThe Ministry of education </p>
                <p>Type of works : Architectural, civil and electrical   </p>
            </div>
            <div className="col-md-5">
                <img src={ahram1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row ">
          <div className="col-md-12 my-1 text-center">
               <img src={ahram2} alt="achegy" className={`img-fluid`}/> 
            </div>
         
           
         
        
          
          </div>

        </div>

    </>
  )
}
