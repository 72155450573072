import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import airport1 from '../../Images/airport1.jpg'
import airport2 from '../../Images/airport2.jpg'







export default function Airport() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-5">
                <h3 className={`${styles.color} my-3`}> VIP lounge of Cairo airport ​</h3>
                <p> Project description: : V.I.P Lounge       </p>
                <p>Type of contract: : Design  </p>
                <p> Area:  200 m2  </p>
                <p>Owner Name : Cairo Airport   </p>
                <p>Type of works : : Architectural and Decorations   </p>
            </div>
            <div className="col-md-7">
                <img src={airport1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-12 my-1 text-center">
               <img src={airport2} alt="achegy" className={`img-fluid`}/> 
            </div>
           
           
        
          
          </div>
        </div>

    </>
  )
}
