import React from 'react'
import styles from '../AllProjFolder/AllProj.module.css'
import projects1 from '../../Images/projects1.png'
import projects2 from '../../Images/projects2.jpg'
import projects3 from '../../Images/projects3.png'
import projects4 from '../../Images/projects4.jpg'
import projects6 from '../../Images/projects6.jpg'
import projects7 from '../../Images/projects7.jpg'
import projects8 from '../../Images/projects8.png'
import projects9 from '../../Images/projects9.png'
import projects10 from '../../Images/projects10.jpg'
import interlock1 from '../../Images/interlock1.jpg'
import Footer from '../../FooterFolder/Footer';
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'


export default function AllProj() {

  useEffect(() => {
    AOS.init({duration: 1500})
  
  }, [])

  return (

    <>
       <section className='container my-5 py-5'>
       <div className="row">

       <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/UrbanProjects" className={`${styles.linkPro}`}> 
            <img src={projects10} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Urban Planning  </p>
            </Link> 
          </div>

        <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
         <Link to="/pharma" className={`${styles.linkPro}`}> 
         <img src={projects1} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3 '>Pharmaceutical Factories</p>
         </Link>  
          </div>
         
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/proj5Edu" className={`${styles.linkPro}`}> 
            <img src={projects7} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Medical And Educational Buildings</p>
            </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/proj1Industerial" className={`${styles.linkPro}`}> 
            <img src={projects3} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Industrial projects</p>
            </Link> 
          </div>
       
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/proj5Banks" className={`${styles.linkPro}`}> 
            <img src={projects6} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Banks</p>
            </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
              <Link to="/ra" className={`${styles.linkPro}`}> 
            <img src={projects8} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Residential & Administrative Buildings </p>
             </Link> 
          </div>
        
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/proj5RB" className={`${styles.linkPro}`}> 
            <img src={projects2} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Office & Commercial Buildings </p>
            </Link> 
          </div>
       
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/interior" className={`${styles.linkPro}`}> 
            <img src={projects9} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Interior Projects</p>
            </Link> 
          </div>

          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/proj5MB" className={`${styles.linkPro}`}> 
            <img src={projects4} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Hotels And Clubs</p>
            </Link> 
          </div>

          <div className="col-md-12 my-3 text-center" data-aos='fade-up'>
          <Link to="/travcoGroup" className={`${styles.linkPro}`}> 
            <img src={interlock1} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Tarvco group buildings </p>
            </Link> 
          </div>
         
        </div>
       </section>

    <Footer />
    
    </>
   
  )
}
