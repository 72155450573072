import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import planning2 from '../../Images/planning2.jpg'
import urban2 from '../../Images/urban2.png'
import urban3 from '../../Images/urban3.jpg'
import urban4 from '../../Images/urban4.jpg'
import urban5 from '../../Images/urban5.jpg'
import urban6 from '../../Images/urban6.jpg'
import urban7 from '../../Images/urban7.1.jpg'
import urban8 from '../../Images/urban8.jpg'
import urban9 from '../../Images/urban9.jpg'
import urban10 from '../../Images/urban10.jpg'
import urban11 from '../../Images/urban11.jpg'
import urban12 from '../../Images/urban12.jpg'
import urban13 from '../../Images/urban13.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'


export default function UrbanProjects() {
   useEffect(() => {
      AOS.init({duration: 1500})
    
    }, [])
  return (

    <>
       <section className='my-5 py-3'>
       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Urban Planing
     
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
        <div className="row">

            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
         <Link to="/Proj5Urban" className={`${styles.linkPro}`}> 
         <img src={planning2} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
         <p className='text-center fw-bold mt-3 '>Teba Unversity - Yanbu Branch</p>
         </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban6" className={`${styles.linkPro}`}> 
           <img src={urban6} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Compound Elite</p>
           </Link>  
              </div>
          
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban3" className={`${styles.linkPro}`}> 
           <img src={urban3} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>El-Fayoum Unversity</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban4" className={`${styles.linkPro}`}> 
           <img src={urban4} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Maghagha City Planning</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban5" className={`${styles.linkPro}`}> 
           <img src={urban5} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Compound Heliopolis Hills</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban2" className={`${styles.linkPro}`}> 
           <img src={urban2} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Teba Unversity - Ola Branch</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban7" className={`${styles.linkPro}`}> 
           <img src={urban7} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Compound Sunny Beach</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban8" className={`${styles.linkPro}`}> 
           <img src={urban8} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '> Compound Elgamya Taawonya Fayoum</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban9" className={`${styles.linkPro}`}> 
           <img src={urban9} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Nobaria City</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban10" className={`${styles.linkPro}`}> 
           <img src={urban10} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Evergreen Compund</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban11" className={`${styles.linkPro}`}> 
           <img src={urban11} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Olivera Compound</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban12" className={`${styles.linkPro}`}> 
           <img src={urban12} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>10th Of Ramdan Housing Project</p>
           </Link>  
              </div>
              <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
           <Link to="/Urban13" className={`${styles.linkPro}`}> 
           <img src={urban13} alt="achegy" className={`${styles.imgSizeProjUrban}`}/>
           <p className='text-center fw-bold mt-3 '>Green Highland</p>
           </Link>  
              </div>
        
          
        </div>
      
       </div>
     
    
    </section>

    
    </>
   
  )
}
