import React from 'react'
import styles from './Footer.module.css'
export default function Footer() {
  return (

    <>
    <div className="container-fluid">
      <div className="row">
       
          <div className={`${styles.footerBg} text-center p-2 text-white col-md-12`}> <i class="fa-regular fa-copyright"></i> 2023 Architectural Consulting House. All Rights Reserved. </div>
        
      </div>
    </div>
   
    </>
   
  )
}
