import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import najar1 from '../../Images/najar1.png'
import najar2 from '../../Images/najar2.png'
import najar3 from '../../Images/najar3.png'
import najar4 from '../../Images/najar4.png'
import najar5 from '../../Images/najar5.png'
import najar6 from '../../Images/najar6.png'
import najar7 from '../../Images/najar7.png'
import najar8 from '../../Images/najar8.png'



export default function Najar() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Najar Factory  </h3>
                <p> Project description: Administrative and production building in Sadat City  </p>
                <p>Type of contract: Design</p>
                <p> Area:  6000 m2 </p>
                <p>Owner Name : Najar Company </p>
                <p>Type of works : Architectural , civil & electromechanical  </p>
            </div>
            <div className="col-md-6">
                <img src={najar1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5">
            <div className="col-md-4 my-1 text-center">
               <img src={najar2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={najar3} alt="achegy" className={`${styles.imgSizeProj}`} /> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={najar4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={najar5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={najar6} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={najar7} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={najar8} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
          </div>
        </div>








  
 
    </>
  )
}
