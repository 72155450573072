import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import em1 from '../../Images/em1.jpg'
import hospital2 from '../../Images/hospital2.png'
import hospital3 from '../../Images/hospital3.png'
import hospital4 from '../../Images/hospital4.jpg'
import hospital5 from '../../Images/hospital5.png'
import hospital6 from '../../Images/hospital6.png'
import hospital7 from '../../Images/hospital7.png'
import portsaid from '../../Images/portsaid.jpg'
import eng from '../../Images/eng1.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'




export default function Proj5Edu() {
  useEffect(() => {
    AOS.init({duration: 1500})
  
  }, [])
  return (

    <>
       <section className='my-5 py-3 '>

       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Medical And Educational Buildings   
     
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
   
        <div className="row">
        
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/Hospital4" className={`${styles.linkPro}`}> 
            <img src={hospital4} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Hana Medical Center</p>
            </Link>  
            </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
          <Link to="/Hospital2" className={`${styles.linkPro}`}> 
          <img src={hospital2} alt="achegy" className={`${styles.imgSizeProj}`}/>
          <p className='text-center fw-bold mt-3 '>ABHA Hospital , Abha , KSA</p>
          </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
          <Link to="/Hospital3" className={`${styles.linkPro}`}> 
          <img src={hospital3} alt="achegy" className={`${styles.imgSizeProj}`}/>
          <p className='text-center fw-bold mt-3 '>NRC Hospital , Jeddah , KSA</p>
          </Link>  
          </div>
        
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
          <Link to="/Hospital5" className={`${styles.linkPro}`}> 
          <img src={hospital5} alt="achegy" className={`${styles.imgSizeProj}`}/>
          <p className='text-center fw-bold mt-3 '>Al Azema Hospital </p>
          </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
          <Link to="/Hospital6" className={`${styles.linkPro}`}> 
          <img src={hospital6} alt="achegy" className={`${styles.imgSizeProj}`}/>
          <p className='text-center fw-bold mt-3 '>Al Osra Hospital </p>
          </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/Hospital7" className={`${styles.linkPro}`}> 
            <img src={hospital7} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>October International Hospital </p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/Hospital1" className={`${styles.linkPro}`}> 
            <img src={em1} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Al Shorouk Hospital</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/portsaid" className={`${styles.linkPro}`}> 
            <img src={portsaid} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Port said School</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/eng" className={`${styles.linkPro}`}> 
            <img src={eng} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Engineers Restaurant </p>
            </Link>  
            </div>
 
        </div>
       </div>

    </section>

    
    </>
   
  )
}
