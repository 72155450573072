import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import thwra from '../../Images/thwra.jpg'
import smoha from '../../Images/smoha.jpg'
import hehia from '../../Images/hehia.jpg'
import zarqa from '../../Images/zarqa.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'



export default function Proj5Banks() {
  useEffect(() => {
    AOS.init({duration: 1500})
  
  }, [])
  return (

    <>
    
    <section className='my-5 py-3 '>
       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'> Banks
  
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
        <div className="row">
        <div className="col-md-3 my-3 text-center" data-aos='fade-up'>
           
         <Link to="/NBEthawra" className={`${styles.linkPro}`}> 
         <img src={thwra} alt="achegy" className={`${styles.imgSizeProjBanks}`}/>
         <p className='text-center fw-bold mt-3 '>National Bank of Egypt (Al-Ahly)– Al Thwra Branch (V.I.P )</p>
         </Link>  
          </div>
          <div className="col-md-3 my-3 text-center" data-aos='fade-up'>
              <Link to="/NBEsmooha" className={`${styles.linkPro}`}> 
            <img src={smoha} alt="achegy" className={`${styles.imgSizeProjBanks}`}/>
            <p className='text-center fw-bold mt-3 '>National Bank of Egypt (Al-Ahly)- Smouha Club branch</p>
             </Link> 
          </div>
          <div className="col-md-3 my-3 text-center" data-aos='fade-up'>
          <Link to="/NBEeastern" className={`${styles.linkPro}`}> 
            <img src={hehia} alt="achegy" className={`${styles.imgSizeProjBanks}`}/>
            <p className='text-center fw-bold mt-3'>National Bank of Egypt (Al-Ahly)- The Eastern branch hehia</p>
            </Link> 
          </div>
          <div className="col-md-3 my-3 text-center" data-aos='fade-up'>
          <Link to="/NBEzarqa" className={`${styles.linkPro}`}> 
            <img src={zarqa} alt="achegy" className={`${styles.imgSizeProjBanks}`}/>
            <p className='text-center fw-bold mt-3'>National Bank of Egypt (Al-Ahly)- Zarqa in Damietta branch </p>
            </Link> 
          </div>
        </div>
       </div>
     
    
    </section>

    
    </>
   
  )
}
