import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import projects1 from '../../Images/projects1.png'





export default function Eg() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> EG GEL for medicines - 6th October city  ​</h3>
                <p> Project description: Design    </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  9760.5 m2 - area of building 6330 m2 </p>
                <p>Owner Name : EG for medicines </p>
                <p>Type of works : Architectural, Civil and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={projects1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

        </div>

    </>
  )
}
