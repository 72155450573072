import React from 'react'
import styles from '../Proj3Folder/Proj3.module.css'
import workshop1 from '../../Images/workshop1.jpg'


export default function WorkShop() {
  return (
   <>
   
   <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Workshop building ​</h3>
                <p> Project description: building for services    </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  900 m2 </p>
                <p>Owner Name : travco Group  </p>
                <p>Type of works : : architectural , structure and electromechanical design</p>
            </div>
            <div className="col-md-6">
                <img src={workshop1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
        </div>
   </>
  )
}
