import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import nilex1 from '../../Images/nilex1.jpg'
import nilex2 from '../../Images/nilex2.png'
import nilex3 from '../../Images/nilex3.png'
import nilex4 from '../../Images/nilex4.png'
import nilex5 from '../../Images/nilex5.png'



export default function Nilex() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Nilex Frozen Factory  </h3>
                <p> Project description: Administrative and production building in Sadat City    </p>
                <p>Type of contract: Design  </p>
                <p> Area:  7243.60 m2 </p>
                <p>Owner Name : Air Force </p>
                <p>Type of works : Architectural, civil and Electromechanical</p>
            </div>
            <div className="col-md-6">
                <img src={nilex1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5 pb-5">
          <div className="col-md-6 my-1 text-center">
               <img src={nilex2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={nilex3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={nilex4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={nilex5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
           
          </div>
        </div>

    </>
  )
}
