import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import urban11p from '../../Images/urban11.jpg'
import urban11p1 from '../../Images/urban11.1.jpg'



export default function urban11() {
  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`container  `}>
        
          <div className="container my-5 py-5 ">
              <div className="row align-items-center">
                <div className="col-md-6">
                    <h3 className={`${styles.color} my-3`}> Olivera Compound ​</h3>
                    <p> Client: Mr. Sherif Hegazy  </p>
                    <p>Location : Cairo - Alex desert road  </p>
                    <p>Services Provided : Project Management Intergrated Documents , Site Supervision  </p>
                </div>
                <div className="col-md-6">
                    <img src={urban11p} alt="achegy" className='img-fluid'/>
                </div>   
              </div>
              <div className="row my-5">
      
      <div className="col-md-12 my-1 text-center">
        <img src={urban11p1} alt="achegy" className={`img-fluid`}/> 
      </div>
     
    </div>

    
          </div>
   
       </div>


    </section>

    
    </>
   
  )
}
