import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import alunilep from '../../Images/sadatfac.jpg'
import alunile1 from '../../Images/alunile1.png'
import alunile2 from '../../Images/alunile2.png'
import alunile3 from '../../Images/alunile3.png'





export default function Alunile() {
  return (

    <>
        <div className="container my-5 py-5">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Alunile Factory  ​</h3>
                <p> Project description: : Administrative and production building in Sadat City </p>
                <p>Type of contract: Design </p>
                <p> Area:  21748 m2</p>
                <p>Owner Name : Alunile </p>
                <p>Type of works : Architectural , civil & electromechanical  </p>
            </div>
            <div className="col-md-6">
                <img src={alunilep} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={alunile1} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={alunile2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={alunile3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            
          </div>
     
        </div>

    </>
  )
}
