import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import ifcg1 from '../../Images/ifcg1.jpg'
import ifcg2 from '../../Images/ifcg2.jpg'
import ifcg3 from '../../Images/ifcg3.jpg'
import ifcg4 from '../../Images/ifcg4.jpg'
import ifcg5 from '../../Images/ifcg5.jpg'
import ifcg6 from '../../Images/ifcg6.jpg'
import ifcg7 from '../../Images/ifcg7.jpg'

export default function IFCG() {
  return (
    <div className="container my-5 py-5 ">
    <div className="row  align-items-center">
      <div className="col-md-6">
          <h3 className={`${styles.color} my-3`}>  Factory   IFCG  ​</h3>
          <p> Project description: admin and production hall </p>
          <p>Type of contract: Design and supervision </p>
          <p> Area:  13000 m2 </p>
          <p>Owner Name : IFCG </p>
          <p>Type of works : architectural , civil and electromechanical design </p>
      </div>
      <div className="col-md-6">
          <img src={ifcg1} alt="achegy" className='img-fluid'/>
      </div>   
    </div>

    <div className="row my-5 pb-5">
    <div className="col-md-4 my-1 text-center">
         <img src={ifcg2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
      </div>
      <div className="col-md-4 my-1 text-center">
         <img src={ifcg3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
      </div>
      <div className="col-md-4 my-1 text-center">
         <img src={ifcg4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
      </div>
      <div className="col-md-4 my-1 text-center">
         <img src={ifcg5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
      </div>
      <div className="col-md-4 my-1 text-center">
         <img src={ifcg6} alt="achegy" className={`${styles.imgSizeProj}`}/> 
      </div>
      <div className="col-md-4 my-1 text-center">
         <img src={ifcg7} alt="achegy" className={`${styles.imgSizeProj}`}/> 
      </div>

 
    </div>
  </div>
  )
}
