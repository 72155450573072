import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import nasserOffice from '../../Images/nasserOffice.jpg'





export default function NasserOffice() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-8">
                <h3 className={`${styles.color} my-3`}> Nasser Rashed Office  Tower, Qatar     ​</h3>
                <p> Project description: administrative Building      </p>
                <p>Type of contract: Design </p>
                <p> Area:  44000 m2 </p>
                <p>Type of works : Architectural coordination’s And Electromechanical </p>
            </div>
            <div className="col-md-4 text-center">
                <img src={nasserOffice} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

        </div>

    </>
  )
}
