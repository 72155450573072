import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import hospital4p from '../../Images/hospital4.jpg'
import hospital4p1 from '../../Images/hospital4.1.jpg'
import hospital4p2 from '../../Images/hospital4.2.jpg'
import hospital4p3 from '../../Images/hospital4.3.jpg'
import hospital4p4 from '../../Images/hospital4.4.jpg'
import hospital4p5 from '../../Images/hospital4.5.jpg'
import hospital4p6 from '../../Images/hospital4.6.jpg'





export default function Hospital4() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Hana Medical Center ​</h3>
                <p> Project description: Medical Center  </p>
                <p>Type of contract: Design </p>
                <p> Area:  1140 m2  </p>
                <p>Type of works : Architectural coordination's and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={hospital4p} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={hospital4p1} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital4p2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital4p3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital4p4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital4p5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital4p6} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
        
          
          </div>
        </div>

    </>
  )
}
