import React from 'react'
import styles from '../Proj3Folder/Proj3.module.css'
import dabaa1 from '../../Images/dabaa1.png'
import dabaa2 from '../../Images/dabaa2.jpg'
import dabaa3 from '../../Images/dabaa3.jpg'
import dabaa4 from '../../Images/dabaa4.jpg'
import dabaa5 from '../../Images/dabaa5.jpg'
import dabaa7 from '../../Images/dabaa7.jpg'
import dabaa8 from '../../Images/dabaa8.jpg'


export default function Dabaa() {
  return (

    <>
        <div className="container my-5 py-5">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> El Dabaa Cold Store  ​</h3>
                <p> Project description: Administrative and production building in Dabaa   </p>
                <p>Type of contract: Design  </p>
                <p> Area:  101376 m2 </p>
                <p>Owner Name : Air Force </p>
                <p>Type of works : Architectural, civil and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={dabaa1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5 pb-5">  
          <div className="col-md-4 my-1 text-center">
               <img src={dabaa3} alt="achegy" className={`${styles.imgSizeProj}`}/>
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={dabaa4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={dabaa5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={dabaa7} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={dabaa8} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={dabaa2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
          </div> 
        </div>

    </>
  )
}
