import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import hospital7p1 from '../../Images/hospital7.1.png'
import hospital7p2 from '../../Images/hospital7.2.png'
import hospital7p3 from '../../Images/hospital7.3.png'
import hospital7p4 from '../../Images/hospital7.4.png'





export default function Hospital7() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> October International Hospital ​</h3>
                <p> Project description: Hospital Building  </p>
                <p>Type of contract: Design </p>
                <p> Area:  3853.76 m2  </p>
                <p>Type of works : Architectural coordination's and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={hospital7p1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
         
         <div className="col-md-4 my-2 text-center">
            <img src={hospital7p2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
         </div>
         <div className="col-md-4 my-2 text-center">
            <img src={hospital7p3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
         </div>
         <div className="col-md-4 my-2 text-center">
            <img src={hospital7p4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
         </div>
   
     
       
       </div>
        </div>

    </>
  )
}
