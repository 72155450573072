import './App.css';
import Navbar from './Components/NavFolder/Navbar';
import Home from './Components/HomeFolder/Home';
import {Routes , Route} from 'react-router-dom';
import Founders from './Components/AboutusFolder/FoundersFolder/Founders';
import PandC from './Components/AboutusFolder/P&Cfolder/P&C';
import Contactus from './Components/ContactusFolder/Contactus';
import Services from './Components/ServicesFolder/Services';
import Pharma from './Components/ProjectsFolder/Proj1Folder/Pharma';
import Proj1Industerial from './Components/ProjectsFolder/Proj1Folder/proj1Industerial';
import RA from './Components/ProjectsFolder/Proj2Folder/RA';
import Interior from './Components/ProjectsFolder/Proj4Folder/Interior';
import Proj5Urban from './Components/ProjectsFolder/Proj5Folder/Proj5Urban';
import Proj5MB from './Components/ProjectsFolder/Proj5Folder/HotelsAndClubs';
import Proj5RB from './Components/ProjectsFolder/Proj5Folder/Office';
import Proj5Banks from './Components/ProjectsFolder/Proj5Folder/Proj5Banks';
import Proj5Edu from './Components/ProjectsFolder/Proj5Folder/Proj5Edu';
import Nerhado from './Components/ProjectsFolder/Proj1Folder/Nerhado';
import Andalos from './Components/ProjectsFolder/Proj1Folder/Andalos';
import Solvida from './Components/ProjectsFolder/Proj1Folder/Solvida';
import Hekma from './Components/ProjectsFolder/Proj1Folder/Hekma'; 
import Riva from './Components/ProjectsFolder/Proj1Folder/Riva';
import Sheraton from './Components/ProjectsFolder/Proj2Folder/Sheraton';
import Golden from './Components/ProjectsFolder/Proj2Folder/Golden';
import Americana from './Components/ProjectsFolder/Proj1Folder/Americana';
import Foodstore from './Components/ProjectsFolder/Proj1Folder/Foodstore';
import Mekka from './Components/ProjectsFolder/Proj2Folder/Mekka';
import Alunile from './Components/ProjectsFolder/Proj1Folder/Alunile';
import AllProj from './Components/ProjectsFolder/AllProjFolder/AllProj';
import NBEthawra from './Components/ProjectsFolder/Proj5Folder/NBEthwra';
import NBEsmooha from './Components/ProjectsFolder/Proj5Folder/NBEsmooha';
import NBEeastern from './Components/ProjectsFolder/Proj5Folder/NBEeastern';
import NBEzarqa from './Components/ProjectsFolder/Proj5Folder/NBEzarqa';
import UrbanProjects from './Components/ProjectsFolder/Proj5Folder/UrbanProjects';
import Urban2 from './Components/ProjectsFolder/Proj5Folder/Urban2';
import Urban3 from './Components/ProjectsFolder/Proj5Folder/Urban3';
import Urban4 from './Components/ProjectsFolder/Proj5Folder/Urban4';
import Urban5 from './Components/ProjectsFolder/Proj5Folder/Urban5';
import Urban6 from './Components/ProjectsFolder/Proj5Folder/Urban6';
import Urban7 from './Components/ProjectsFolder/Proj5Folder/Urban7';
import Urban8 from './Components/ProjectsFolder/Proj5Folder/Urban8';
import Urban9 from './Components/ProjectsFolder/Proj5Folder/Urban9';
import Urban10 from './Components/ProjectsFolder/Proj5Folder/Urban10';
import Urban11 from './Components/ProjectsFolder/Proj5Folder/Urban11';
import Urban12 from './Components/ProjectsFolder/Proj5Folder/Urban12';
import Urban13 from './Components/ProjectsFolder/Proj5Folder/Urban13';
import Hospital1 from './Components/ProjectsFolder/Proj5Folder/Hospital1';
import Hospital2 from './Components/ProjectsFolder/Proj5Folder/Hospital2';
import Hospital3 from './Components/ProjectsFolder/Proj5Folder/Hospital3';
import Hospital4 from './Components/ProjectsFolder/Proj5Folder/Hospital4';
import Hospital5 from './Components/ProjectsFolder/Proj5Folder/Hospital5';
import Hospital6 from './Components/ProjectsFolder/Proj5Folder/Hospital6';
import Hospital7 from './Components/ProjectsFolder/Proj5Folder/Hospital7';
import Portsaid from './Components/ProjectsFolder/Proj5Folder/Portsaid';
import Eg from './Components/ProjectsFolder/Proj1Folder/Eg';
import Bio from './Components/ProjectsFolder/Proj1Folder/Bio';
import Dabaa from './Components/ProjectsFolder/Proj3Folder/Dabaa';
import NaserCity from './Components/ProjectsFolder/Proj5Folder/NaserCity';
import Mina from './Components/ProjectsFolder/Proj5Folder/Mina';
import Asia from './Components/ProjectsFolder/Proj5Folder/Asia';
import RiverClub from './Components/ProjectsFolder/Proj5Folder/RiverClub';
import AhramClub from './Components/ProjectsFolder/Proj5Folder/AhramClub';
import Eng from './Components/ProjectsFolder/Proj5Folder/Eng';
import Nilex from './Components/ProjectsFolder/Proj1Folder/Nilex';
import Najar from './Components/ProjectsFolder/Proj1Folder/Najar';
import Nasser from './Components/ProjectsFolder/Proj2Folder/Nasser';
import NasserOffice from './Components/ProjectsFolder/Proj2Folder/NasserOffice';
import Cargos from './Components/ProjectsFolder/Proj5Folder/Cargos';
import Crystal from './Components/ProjectsFolder/Proj5Folder/Crystal';
import Info from './Components/ProjectsFolder/Proj5Folder/Info';
import Waleed from './Components/ProjectsFolder/Proj5Folder/Waleed';
import Airport from './Components/ProjectsFolder/Proj5Folder/Airport';
import Warehouse from './Components/ProjectsFolder/Proj5Folder/Warhouse';
import Arak from './Components/ProjectsFolder/Proj5Folder/Arak';
import Cid from './Components/ProjectsFolder/Proj1Folder/Cid';
import Escap from './Components/ProjectsFolder/Proj1Folder/Escap';
import IFCG from './Components/ProjectsFolder/Proj1Folder/IFCG';
import Rabie from './Components/ProjectsFolder/Proj1Folder/Rabie';
import Kenana from './Components/ProjectsFolder/Proj1Folder/Kenana';
import TravcoGroup from './Components/ProjectsFolder/Proj3Folder/TravcoGroup';
import InterLock from './Components/ProjectsFolder/Proj3Folder/InterLock';
import CentralKitchen from './Components/ProjectsFolder/Proj3Folder/CentralKitchen';
import ServiceBuilding from './Components/ProjectsFolder/Proj3Folder/ServiceBuilding';
import WorkShop from './Components/ProjectsFolder/Proj3Folder/WorkShop';
import Storage from './Components/ProjectsFolder/Proj3Folder/Storage';
import ScrollToTop from './Components/ScrollToTopFolder/ScrollToTop';
function App() {
  return (
    <>
    <ScrollToTop>

    <Navbar/>

    <Routes> 
            <Route path='*' element={ <Home/>}/>
            <Route path='home' element={ <Home/>}/>
            <Route path='founders' element={ <Founders/>}/>
            <Route path='p&c' element={ <PandC/>}/>
            <Route path='allproj' element={ <AllProj/>}/>
            <Route path='pharma' element={ <Pharma/>}/>
            <Route path='proj1Industerial' element={ <Proj1Industerial/>}/>
            <Route path='proj5Banks' element={ <Proj5Banks/>}/>
            <Route path='NBEthawra' element={ <NBEthawra/>}/>
            <Route path='NBEsmooha' element={ <NBEsmooha/>}/>
            <Route path='NBEeastern' element={ <NBEeastern/>}/>
            <Route path='NBEzarqa' element={ <NBEzarqa/>}/>
            <Route path='proj5Edu' element={ <Proj5Edu/>}/>
            <Route path='proj5RB' element={ <Proj5RB/>}/>
            <Route path='proj5MB' element={ <Proj5MB/>}/>
            <Route path='nerhado' element={ <Nerhado/>}/>
            <Route path='andalos' element={ <Andalos /> }/>
            <Route path='solvida' element={ <Solvida /> }/>
            <Route path='hekma' element={ <Hekma /> }/>
            <Route path='riva' element={ <Riva /> }/>
            <Route path='eg' element={ <Eg /> }/>
            <Route path='bio' element={ <Bio /> }/>
            <Route path='americana' element={ <Americana/>}/>
            <Route path='foodstore' element={ <Foodstore/>}/>
            <Route path='alunile' element={ <Alunile/>}/>
            <Route path='dabaa' element={ <Dabaa/>}/>
            <Route path='nilex' element={ <Nilex/>}/>
            <Route path='najar' element={ <Najar/>}/>
            <Route path='ra' element={ <RA/>}/>
            <Route path='sheraton' element={ <Sheraton/>}/>
            <Route path='golden' element={ <Golden/>}/>
            <Route path='nasser' element={ <Nasser/>}/>
            <Route path='nasserOffice' element={ <NasserOffice/>}/>
            <Route path='ksa' element={ <Mekka/>}/>
            <Route path='interior' element={ <Interior/>}/>
            <Route path='proj5Urban' element={ <Proj5Urban/>}/>
            <Route path='UrbanProjects' element={ <UrbanProjects/>}/>
            <Route path='Urban2' element={ <Urban2/>}/>
            <Route path='Urban3' element={ <Urban3/>}/>
            <Route path='Urban4' element={ <Urban4/>}/>
            <Route path='Urban5' element={ <Urban5/>}/>
            <Route path='Urban6' element={ <Urban6/>}/>
            <Route path='Urban7' element={ <Urban7/>}/>
            <Route path='Urban8' element={ <Urban8/>}/>
            <Route path='Urban9' element={ <Urban9/>}/>
            <Route path='Urban10' element={ <Urban10/>}/>
            <Route path='Urban11' element={ <Urban11/>}/>
            <Route path='Urban12' element={ <Urban12/>}/>
            <Route path='Urban13' element={ <Urban13/>}/>
            <Route path='Hospital1' element={ <Hospital1/>}/>
            <Route path='Hospital2' element={ <Hospital2/>}/>
            <Route path='Hospital3' element={ <Hospital3/>}/>
            <Route path='Hospital4' element={ <Hospital4/>}/>
            <Route path='Hospital5' element={ <Hospital5/>}/>
            <Route path='Hospital6' element={ <Hospital6/>}/>
            <Route path='Hospital7' element={ <Hospital7/>}/>
            <Route path='portsaid' element={ <Portsaid/>}/>
            <Route path='nasercity' element={ <NaserCity/>}/>
            <Route path='mina' element={ <Mina/>}/>
            <Route path='asia' element={ <Asia/>}/>
            <Route path='arak' element={ <Arak/>}/>
            <Route path='river' element={ <RiverClub/>}/>
            <Route path='ahram' element={ <AhramClub/>}/>
            <Route path='eng' element={ <Eng/>}/>
            <Route path='cargos' element={ <Cargos/>}/>
            <Route path='crystal' element={ <Crystal/>}/>
            <Route path='info' element={ <Info/>}/>
            <Route path='waleed' element={ <Waleed/>}/>
            <Route path='airport' element={ <Airport/>}/>
            <Route path='warehouse' element={ <Warehouse/>}/>
            <Route path='cid' element={ <Cid/>}/>
            <Route path='escap' element={ <Escap/>}/>
            <Route path='ifcg' element={ <IFCG/>}/>
            <Route path='rabie' element={ <Rabie/>}/>
            <Route path='kenana' element={ <Kenana/>}/>
            <Route path='travcoGroup' element={ <TravcoGroup/>}/>
            <Route path='interlock' element={ <InterLock/>}/>
            <Route path='centralkitchen' element={ <CentralKitchen/>}/>
            <Route path='servicebuilding' element={ <ServiceBuilding/>}/>
            <Route path='workshop' element={ <WorkShop/>}/>
            <Route path='storage' element={ <Storage/>}/>
            <Route path='services' element={ <Services/>}/>
            <Route path='contactus' element={ <Contactus/>}/>            
  </Routes>
    
  </ScrollToTop>
   
    </>
  );
}

export default App;
