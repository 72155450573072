import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import hehia from '../../Images/hehia.jpg'
import hehia1 from '../../Images/hehia1.jpg'
import hehia2 from '../../Images/hehia2.jpg'






export default function NBEthawra() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}> National Bank of Egypt (Al-Ahly)- The Eastern branch hehia ​</h3>
                <p> Project description: Development Bank  </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  1000 m2  </p>
                <p>Owner Name : National Bank Of Egypt (Al-Ahly) </p>
                <p>Type of works : Decoration and Electromechanical </p>
            </div>
            <div className="col-md-5">
                <img src={hehia} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-6 my-1 text-center">
               <img src={hehia1} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-2 text-center">
               <img src={hehia2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>

          </div>
        </div>

    </>
  )
}
