import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import hospital6p1 from '../../Images/hospital6.png'
import hospital6p2 from '../../Images/hospital6.1.png'
import hospital6p3 from '../../Images/hospital6.2.png'
import hospital6p4 from '../../Images/hospital6.4.png'





export default function Hospital6() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> AL Osra Hospital ​</h3>
                <p> Project description: Hospital Building  </p>
                <p>Type of contract: Design </p>
                <p> Area:  1881.00 m2  </p>
                <p>Type of works : Architectural coordination's and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={hospital6p1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
         
            <div className="col-md-4 my-2 text-center">
               <img src={hospital6p2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital6p3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital6p4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
      
        
          
          </div>
        </div>

    </>
  )
}
