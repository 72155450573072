import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import portsaid from '../../Images/portsaid.jpg'
import portsaid1 from '../../Images/portsaid1.jpg'



export default function Portsaid() {
  return (

    <>
       <section className='my-5 py-5 '>
       <div  className={`container  `}>
        
        
              <div className="row align-items-center">
                <div className="col-md-6">
                    <h3 className={`${styles.color} my-3`}> Port said School ​</h3>
                    <p> Project description: Educational Building    </p>
                    <p>Type of contract: Development Design and supervision   </p>
                    <p> Area:  10000 m2  </p>
                    <p>Owner Name : Education Development Fund  </p>
                    <p>Type of works : Architectural and mechanical   </p>
                </div>
                <div className="col-md-6">
                    <img src={portsaid} alt="achegy" className='img-fluid'/>
                </div>   
              </div>
              <div className="row my-5">
      
              <div className="col-md-12 my-1 text-center">
                <img src={portsaid1} alt="achegy" className={`img-fluid`}/> 
              </div>
              
  
     
    
    </div>
          </div>
   
      


    </section>

    
    </>
   
  )
}
