import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import rabie1 from '../../Images/rabie1.jpg'
import rabie2 from '../../Images/rabie2.jpg'
import rabie3 from '../../Images/rabie3.jpg'
import rabie4 from '../../Images/rabie4.jpg'

export default function Rabie() {
  return (
   <>
           <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Rabie al – takyeef factory , 6th of October city ​</h3>
                <p> Project description: : admin and production hall     </p>
                <p>Type of contract: design and supervision  </p>
                <p> Area:  8000 m2 </p>
                <p>Type of works : architectural , civil and electromechanical design </p>
            </div>
            <div className="col-md-6 text-center">
                <img src={rabie2} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
    <div className="col-md-4 my-1 text-center">
         <img src={rabie1} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
      </div>
      <div className="col-md-4 my-1 text-center">
         <img src={rabie3} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
      </div>
      <div className="col-md-4 my-1 text-center">
         <img src={rabie4} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
      </div>


 
         </div>
        
        </div>
   </>
  )
}
