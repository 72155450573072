import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import bio1 from '../../Images/bio1.jpg'





export default function Bio() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-8">
                <h3 className={`${styles.color} my-3`}> Biological products and medicines factory - 6th octover city   ​</h3>
                <p> Project description: Administrative , production and store building 6th october city    </p>
                <p>Type of contract: Design </p>
                <p> Area:  6000 m2 </p>
                <p>Owner Name : EG for medicines </p>
                <p>Type of works : Architectural </p>
            </div>
            <div className="col-md-4">
                <img src={bio1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

        </div>

    </>
  )
}
