import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import nasercity from '../../Images/nasercity1.jpg'
import mina from '../../Images/mina.png'
import asia from '../../Images/asia.jpg'
import arak from '../../Images/arak.jpg'
import river from '../../Images/River2.jpg'
import ahram from '../../Images/ahram1.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'


export default function Proj5MB() {
  useEffect(() => {
    AOS.init({duration: 1500})
  
  }, [])
  return (

    <>
       <section className='my-5 py-3 '>
   

       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Hotels And Clubs   
      
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
        <div className="row">
    
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
              <Link to="/nasercity" className={`${styles.linkPro}`}> 
              <img src={nasercity} alt="achegy" className={`${styles.imgSizeProj}`}/>
              <p className='text-center fw-bold mt-3 '> Nasr City Hotel </p>
              </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
              <Link to="/mina" className={`${styles.linkPro}`}> 
              <img src={mina} alt="achegy" className={`${styles.imgSizeProj}`}/>
              <p className='text-center fw-bold mt-3 '>Mina Ramada Hotel , KSA </p>
              </Link>  
          </div>
          
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
              <Link to="/arak" className={`${styles.linkPro}`}> 
              <img src={arak} alt="achegy" className={`${styles.imgSizeProj}`}/>
              <p className='text-center fw-bold mt-3 '>Arak Makah Hotel  </p>
              </Link>  
          </div>

          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
              <Link to="/asia" className={`${styles.linkPro}`}> 
              <img src={asia} alt="achegy" className={`${styles.imgSizeProj}`}/>
              <p className='text-center fw-bold mt-3 '>Asia Towers </p>
              </Link>  
          </div>
      
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
              <Link to="/river" className={`${styles.linkPro}`}> 
              <img src={river} alt="achegy" className={`${styles.imgSizeProj}`}/>
              <p className='text-center fw-bold mt-3 '>Diplomat River Club </p>
              </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>  
              <Link to="/ahram" className={`${styles.linkPro}`}> 
              <img src={ahram} alt="achegy" className={`${styles.imgSizeProj}`}/>
              <p className='text-center fw-bold mt-3 '>Al Ahram Club </p>
              </Link>  
          </div>
        </div>
       </div>
    </section>

    
    </>
   
  )
}
