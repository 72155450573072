import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import info1 from '../../Images/info1.jpg'
import info2 from '../../Images/info2.jpg'
import info3 from '../../Images/info3.jpg'






export default function Info() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}> Information Center building -National Center for research ​</h3>
                <p> Project description: 5 Administrative Floors      </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  5000 m2  </p>
                <p>Owner Name : National Center for research</p>
                <p>Type of works : : Architectural, Electromechanical and Decorations  </p>
            </div>
            <div className="col-md-5">
                <img src={info1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-6 my-1 text-center">
               <img src={info2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={info3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
           
        
          
          </div>
        </div>

    </>
  )
}
