import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import em1 from '../../Images/em1.jpg'
import hos1 from '../../Images/hospital1.1.jpg'
import hos2 from '../../Images/hospital1.2.jpg'






export default function Hospital1() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Al Shorouk Hospital  ​</h3>
                <p> Project description: Hospital Building  </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  5000 m2  </p>
                <p>Type of works : Architectural , Decoration and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={em1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-6 my-1 text-center">
               <img src={hos1} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-2 text-center">
               <img src={hos2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
           
         
        
          
          </div>

        </div> 

    </>
  )
}
