import React from 'react'
import styles from './Contuctus.module.css'



export default function Contactus() {
  return (
<>

    <section className='mb-3 pb-3' id='contactus'> 
        <div className="container">
<div className={`${styles.bgCard}  mt-3 pt-4 pb-4 `}>
<div className={`row  p-1`}>
       
       <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>
             Contact Us
       <div className={`${styles.middleTric} position-absolute top-0 start-50 translate-middle `}> c</div>
       <div className="shortline mt-2"></div>
       <div className="longline"></div>
       <div className="shortline mb-3"></div>
       </h2>

         <div className='col-md-5 py-1'>
        
       <div className='d-flex align-items-center my-1 '>
          <div className='me-5'>
          <i className={`${styles.iconsColor} fa-solid fa-location-dot ps-3 fs-1`}></i> 
          </div>
          <div >
        <p className='text-muted'>Address</p>
        <p className={`mainColor`}>6th of October city , Central Axis Parallel Road , </p>
        <p className={`mainColor`}>City Star Towers , Tower No. 4 –3rd floor – 6th flat </p>
          </div>
       </div>
       <div className='greenLine w-25'></div>
       <div className='d-flex align-items-center  my-1'>
          <div  className='me-5'>
          <i className={`${styles.iconsColor} fa-solid fa-mobile ps-3 fs-1`}></i> 
          </div>
          <div >
        <p className='text-muted'>Mobile</p>
     
        <p> <span className='text-muted'>+2</span> <a href="tel:+201121530307" className={`${styles.anchorColor}`}> 01121530307</a></p>
        <p> <span className='text-muted'>+2</span> <a href="tel:+201113419811" className={`${styles.anchorColor}`}> 01113419811</a></p>
          </div> 
       </div> 
       <div className='greenLine w-25'></div>
       <div className='d-flex align-items-center  my-1'>
          <div  className='me-5'>
          <i className={`${styles.iconsColor} fa-solid fa-fax ps-3 fs-1`}></i> 
          </div>
          <div>
        <p className='text-muted'>Fax</p>
        <p>  <a href="tel:+0238244935" className={`${styles.anchorColor}`}> 0238244935</a></p>
       
          </div>
       </div> 
       <div className='greenLine w-25'></div>
       <div className='d-flex align-items-center  my-1'>
          <div  className='me-5'>
          <i className={`${styles.iconsColor} fa-solid fa-envelope ps-3 fs-1`}></i> 
          </div>
          <div>
        <p className='text-muted'>E-mail</p>
         <a href="mailto:info@achegy.com" className={`${styles.anchorColor}`}> info@achegy.com </a>  
       
          </div>
       </div> 
         

         
         </div>

         <div className="col-md-7 py-1">


         <iframe title='ach' className='m-auto w-100 h-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4262.395652227217!2d30.920277882391048!3d29.9585412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458560d6918ea69%3A0xcbdb475b8dea016d!2sCity%20Stars%20Towers%20Mall%20October!5e1!3m2!1sar!2seg!4v1678539077355!5m2!1sar!2seg" 
         
         style={{border:"0"}}
         allowfullscreen="" 
         loading="lazy" 
         referrerpolicy="no-referrer-when-downgrade">
         </iframe>
         
         </div>

      
    </div>
</div>

       
      </div>
    </section>


 
    </>    
   
  )
}

