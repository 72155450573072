import React, { useRef } from 'react'
import styles from './Form.module.css'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';


export default function Form()
 {
  const form = useRef();
 
  

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mcrfiv9', 'template_4udmvas', form.current, '00Arxrq14808C96L4')
      .then((result) => {
          console.log(result.text);
          e.target.reset()
      }, (error) => {
          console.log(error.text);
          e.target.reset()
      });
}
  return (

    <>
   
     <div className='container my-5'>
<div className={`${styles.bgCard} p-5`}>
  
<div className={` row `}>
         <div className="col-md-6">
           <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>
              Stay in touch
              <div className={`${styles.middleTric} position-absolute top-0 start-50 translate-middle `}> s </div>
              <div className="shortline mt-2"></div>
              <div className="longline"></div>
              <div className="shortline mb-2"></div>
            </h2>


<div className=" my-5"> 
<form ref={form} onSubmit={sendEmail}>
<input className={`${styles.formBorder} form-control mb-4 p-2 shadow-none`} type="text" placeholder="Name" aria-label="default input example" name="user_name"/>
<input className={`${styles.formBorder} form-control mb-4 p-2 shadow-none`} type="email" placeholder="Email" aria-label="default input example" name="user_email"/>
<input className={`${styles.formBorder} form-control mb-4 p-2 shadow-none`}  type="number" placeholder="Phone" aria-label="default input example" name="user_num"/>
<textarea className={`${styles.formBorder} form-control shadow-none`} type="text" id="exampleFormControlTextarea1" placeholder="Message"rows="4" name='user_text'></textarea>
<div className="text-center">
<button type="submit" className={`${styles.btnEdit}  fw-bold mt-4`}> <i className="fa-solid fa-paper-plane"></i> Send Message</button>
</div>
</form>

</div>

  </div>

  <div className="col-md-6" id='career'>
      <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>
            Social media
    <div className={`${styles.middleTric} position-absolute top-0 start-50 translate-middle `}> s </div>
    <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
      </h2>


      <div className={`${styles.social} d-flex justify-content-center my-3`}>
            
                <div className='my-3'>  <Link to='https://www.facebook.com/ach.decorations?mibextid=LQQJ4d' target={"_blank"}> <i className="fa-brands fa-facebook fs-2 mx-2"> </i> </Link>  </div> 
                <div className='my-3'>  <Link to='https://instagram.com/ach___0?igshid=YmMyMTA2M2Y=' target={"_blank"}><i className="fa-brands fa-instagram fs-2 mx-2"></i></Link>  </div> 
                <div className='my-3'>  <Link to='https://www.linkedin.com/company/architecture-consulting-house/' target={"_blank"}> <i className="fa-brands fa-linkedin fs-2 mx-2"></i> </Link></div> 
   
      </div>

      

      <h2 className='text-center fs-2 fw-bolder position-relative mainColor mt-5' >
         Careers
        <div className={`${styles.middleTric} position-absolute top-0 start-50 translate-middle `}> c </div>
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
      </h2>
       <p className='text-center mt-3 fs-5 text-muted'> To apply for a job with <span className={`${styles.color} fs-4`}> ACH </span>  please send your C.V. to: </p>
       <p className='text-center'> <a href="mailto:info@achegy.com" className={`${styles.anchorColor} fs-5`}> info@achegy.com </a>  </p>
  </div>
 
      </div>
</div>
     
</div>
    </>
   
  )
}











