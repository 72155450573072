import React from 'react'
import styles from '../Proj4Folder/Proj4.module.css'
import interior1 from '../../Images/interior1.jpg'
import interior2 from '../../Images/interior2.jpg'
import interior3 from '../../Images/interior3.jpg'
import interior4 from '../../Images/interior4.jpg'
import interior5 from '../../Images/interior5.jpg'
import interior6 from '../../Images/interior6.jpg'
import interior7 from '../../Images/interior7.jpg'
import interior8 from '../../Images/interior8.jpg'
import interior9 from '../../Images/interior9.jpg'
import interior10 from '../../Images/interior10.jpg'
import interior11 from '../../Images/interior11.jpg'
import interior12 from '../../Images/interior12.jpg'
import interior13 from '../../Images/interior13.jpg'
import interior14 from '../../Images/interior14.jpg'
import interior15 from '../../Images/interior15.jpg'
import interior16 from '../../Images/interior16.jpg'
import interior17 from '../../Images/interior17.jpg'
import interior18 from '../../Images/interior18.jpg'
import interior19 from '../../Images/interior19.jpg'
import interior20 from '../../Images/interior20.jpg'
import interior21 from '../../Images/interior21.jpg'
import interior22 from '../../Images/interior22.jpg'
import interior23 from '../../Images/interior23.jpg'
import interior24 from '../../Images/interior24.jpg'
import interior25 from '../../Images/interior25.jpg'
import interior26 from '../../Images/interior26.jpg'
import interior27 from '../../Images/interior27.jpg'
import interior28 from '../../Images/interior28.jpg'
import interior29 from '../../Images/interior29.jpg'
import interior30 from '../../Images/interior16.jpg'
import interior31 from '../../Images/interior31.jpg'
import interior32 from '../../Images/interior32.jpg'
import interior33 from '../../Images/interior33.jpg'
import interior34 from '../../Images/interior34.jpg'
import interior35 from '../../Images/interior35.jpg'
import interior36 from '../../Images/interior35.jpg'








export default function Interior() {

  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Interior Design 
 
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
        <div className="row">

        <div className="col-md-4 my-3 text-center" >
         <img src={interior16} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior17} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior18} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior19} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior20} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior21} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior22} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior23} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior24} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior25} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior26} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior27} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior28} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior29} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior30} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior31} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior32} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior33} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior34} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior35} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center" >
         <img src={interior36} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>

        <div className="col-md-4 my-3 text-center" >
         <img src={interior1} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior2} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior3} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior4} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior5} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior6} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior7} alt="achegy" className={`${styles.imgSizeProj}`}/>
       
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior8} alt="achegy" className={`${styles.imgSizeProj}`}/>
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior9} alt="achegy" className={`${styles.imgSizeProj}`}/>
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior10} alt="achegy" className={`${styles.imgSizeProj}`}/>
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior11} alt="achegy" className={`${styles.imgSizeProj}`}/>
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior12} alt="achegy" className={`${styles.imgSizeProj}`}/>
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior13} alt="achegy" className={`${styles.imgSizeProj}`}/>
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior14} alt="achegy" className={`${styles.imgSizeProj}`}/>
        </div>
        <div className="col-md-4 my-3 text-center">
         <img src={interior15} alt="achegy" className={`${styles.imgSizeProj}`}/>
        </div>

          
        </div>
       </div>
       
    </section>

    
    </>
   
  )
}
