import React from 'react'
import servicesImg from '../Images/services.jpg'
import styles from '../ServicesFolder/Services.module.css'
import Footer from '../FooterFolder/Footer';

export default function Services() {
  return (
  <>
  <div className="container my-5 py-5">
    <div className="row">
      <div className={`${styles.bgCard} col-md-12`}>
        <h2 className='text-center mainColor pt-3'> Organizational Structure </h2>
        <div className="shortline mt-2"></div>
    <div className="longline"></div>
    <div className="shortline mb-2"></div>
        <div className='text-center'> <img src={servicesImg} alt="" className='img-fluid '/> </div>
        
      </div>
      <div className="col-md-12 my-3">
        <h4 className='fs-2'> Bureau Activity </h4>
        <p className={`${styles.color} fs-4`}>Provide advisory services to engineering projects include the following:</p>
        <p> <span className={`${styles.color}`}>1:</span>  Architectural Design and Drawing Execution of public and private buildings.</p>
        <p> <span className={`${styles.color}`}>2:</span>  Continuous or periodic supervision on implementation and quality control.</p>
        <p> <span className={`${styles.color}`}>3:</span> Project – Urban planning, Urban Design and Infrastructure.</p>
        <p> <span className={`${styles.color}`}>4:</span> Preparation of designs and execution drawings operational. </p>
        <p> <span className={`${styles.color}`}>5:</span> Preparation of technical specifications and lists of quantities and general conditions for projects.</p>
        <p> <span className={`${styles.color}`}>6:</span> Examining and evaluating and reviewing designs for constructions.</p>
        <p> <span className={`${styles.color}`}>7:</span> Analysis and examination of tenders and recommendations for action.</p>
        <p> <span className={`${styles.color}`}>8:</span> Project Management.</p>
        <p> <span className={`${styles.color}`}>9:</span> Feasibility studies and economic studies needed for engineering projects </p>
      </div>
      <div className="col-md-12">

      <h4 > Design Division </h4>
      <p><span className={`${styles.color}`}>A.C.H</span> offers engineering and design services for architecture, civil, structural, mechanical, utilities, and electrical engineering, telecommunications networking, and security and utility network designs. A.C.H engineers, responsible to client management, reconcile all design interests and perspectives.</p>
      <p><span className={`${styles.color}`}>A.C.H</span> offers extensive services for schematic design, design development, and construction documents.</p>
      <h5 className={`${styles.color}`}> Architectural Design Department:</h5>
      <p>Supervised By Pro. Dr. / Ashraf Elsayed ElBastawissi - Cairo University – Pro at Housing & Building National Research Center.</p>
      <p>Supervised By Eng. / Ashraf Abdel Latef .</p>
      <span>Through the years of experience this section formatted its team. A very well and trained team carrying the architectural designs for educational, industrial, residential, offices and even infra-structures projects. To offer the service completely lately talented team of interior designers joined the architectural team of A.C.H.</span>
      <p className='text-muted'>Capabilities :  <span> Facilities Master Planning ,
         Space Programming ,
         Exterior Design ,
         Interior Design ,
         Graphic Design</span></p>

      <h5 className={`${styles.color}`}>Regional & Urban Planning Department:</h5>
      <p>Supervised By Pro. Dr. / Ashraf Elsayed ElBastawissi - Cairo University – Pro at Housing & Building National Research Center.</p>
      
      <span>A.C.H planners are experts in regional and urban planning. This sector is well staffed with professionals who have the technical competence and the availability of resources to support our client’s needs.</span>
      <p className='text-muted'>Capabilities :</p>
      <p>(Planning) <span> Regional Planning , Urban Planning , Urban Studies</span></p>
      <p>(Infrastructure) <span>  Environmental Engineering , Road Designs , Irrigation Projects , Drainage Projects , Power Plants</span></p>
      <p>(Landscape) <span> Hard Escapes , Soft Escapes , Irrigation  Networks : Design of pharmaceutical plants and production lines project design and production lines (sterile areas) and manufacturing processes adapted to international standards (cGMP) and supervised by the Engineer/Yasser Diab</span></p>
     
      <h5 className={`${styles.color}`}> Structural  Design Department:</h5>
      <p>Supervised By Pro. Dr. / Hassan Mohamed Allam</p>
      <span>More than 35 years of experience. Heads and supervisors of this section gained this experience through these years and gained their team work attitude from working together in A.C.H for more than 25 years. Most of the team leaders in this section are staff in Egyptian Universities or for Housing and Building Research Center so they are combining their academic experience together with the vast practical experience to serve the client.</span>
      <p className='text-muted'>Capabilities :  
      <span> Structural analysis ,
             Structural design ,
             Re-habilitation & Repair work ,
             Revision & Auditing Structural Design Safety 
      </span> </p>

      <h5 className={`${styles.color}`}> Electromechanical Department:</h5>
      <p>Supervised By DR / MOhameed Abu Al-futuh &  Pro. Dr. / Ahmed Medhat</p>
      <span>A.C.H team of engineers specialized and experienced in this field work in cooperation with consultants who used to work with us to offer client engineering services in the following fields:</span>
      <p className='text-muted'>Capabilities : </p>
      <p> (Sanitary work) Domestic water system , Drainage water system , Sewage and storm water system , Industrial drainage water system , Irrigation water system
      </p> 
      <p> (Mechanical work) HVAC , Plumbing , Compressed air system , Environment Engineering </p> 
      <p> (Life Systems) Fire Alarm , Fire Fighting , Smoke Control  </p>
       <p> (Electrical Engineering) Electric Power , Lighting , Telephone Systems , CCTV Systems , Data Systems , Street lighting  'Industrial engineering'  </p>

        <h4> Project Management Division </h4>
        
      <p className={`${styles.color}`}>Supervised By.  Dr. / Waleed Elmalah</p>
      <p className='text-muted'>Capabilities : </p>
      <p>Procure and control all contracts and related tasks.</p>
      <p>Co-ordinate and communicate with all parties involved.</p>
      <p>Assist the client in identifying the best specialist for special tasks.</p>
      <p>Manage the tendering phases.</p>
      <p>Control activities’ schedule.</p>

      <p className='text-muted'>A.C.H Pre-design services : </p>
      <p> Evaluate client's needs and resources logically and consistently.</p>
      <p>Feasibility Studies and Analysis.</p>
      <p>Primarily facilities analysis.</p>
      <p>Planning and programming.</p>
      <p>Develop maximum advantage over time.</p>

      <p className='text-muted'>A.C.H Through-design services : </p>
      <p> Time Schedule.</p>
      <p>Value Engineering.</p>
      <p>Constructability Studies.</p>

      <p className='text-muted'>A.C.H Post-design services : </p>
      <p> Assist clients in evaluating completed projects.</p>
      <p> Maintaining buildings and physical plant environment.</p>
      <p>Reviewing and revising plans as a result of changing circumstances.</p>

      <p className='text-muted'>A.C.H pre-construction services such as : </p>
      <p> Construction review.</p>
      <p> Cost estimates.</p>
      <p> Surveys.</p>
      <p>Community outreach programs.</p>
      
      <h4> Construction Management Division </h4>
        
      <p className={`${styles.color}`}>Supervised By.  Dr. / Waleed Elmalah</p>
      <p>The Construction management task is to offer a number of advantages to the client. A.C.H procedures and methods are designed to anticipate problems and conflicts so they can be resolved quickly.</p>
      <p>A.C.H uses a project team approach for developing designs for its projects. This approach uses Principal-in-Charge, and a Project Manager to orchestrate the work of the designers, programmers, planners, consultants, and production personnel.</p>
      <p>A construction management plan, tailored for each project, defines procedures and responsibilities for the successful execution of construction, including common elements through the life time of each project .</p>
      <p>A.C.H provides objective, independent oversight throughout the construction phase to manage and supervise the construction and all site activities to ensure quality, adherence to schedule and cost estimates and to ensure the safety of all personnel involved.</p>

      <p className='text-muted'>Capabilities :   </p>
      <span> Quality assurance , Document control , Submittal management , Payment verification , Change control , Safety compliance</span>
    
       
      <p className='text-muted'>A.C.H Throygh-construction services : </p>
      <p> QC / QA </p>
      <p> Execution Monitoring</p>
      <p>Document Control</p>
      <p>Safety Auditing</p>
      <p>Cost Control</p>

      <p className='text-muted'>A.C.H post-construction services : </p>
      <p> Testing </p>
      <p> Interim operations and maintenance</p>
      <p>Facility turnover assistance</p>
      <p>Claims management</p>

      <h4> Quality Control Department </h4>
        
        <p className={`${styles.color}`}>Supervised By Pro. Dr. / Ahmed Elgabry</p>
        <p>At A.C.H, quality control procedures are tailored to each project and to the performed work. Quality assurance goes hand-in-hand with quality control as the means to verify that a project's quality requirements are being met.</p>
        <p>The guidelines in our rigorous corporate quality assurance program ensure the quality of our services and those of our sub-consultants. Quality control team is always seeking perfection as they are responsible for the quality of the overall product provided to the client.</p>
         
      <p className='text-muted'>Capabilities : </p>
      <p> Quality plan development, implementation verification, and maintenance</p>
      <p> Design and procurement document review</p>
      <p> Procurement quality control and inspection</p>
      <p>  Quality improvement process administration </p>

      <h4>  Financial and Administration Department : </h4>
        
        <p className={`${styles.color}`}> Supervised By ACC / Hassan Abden </p>
        <p>A.C.H is supported by its financial and Administrative team as they are doing their role perfectly and effectively giving every powerful push to the technical staff and creating for them healthy working environment .</p>
      
      </div>
    </div>
  </div>
    <Footer />
  </>
  )
}
