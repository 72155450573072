import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import nasercity1 from '../../Images/nasercity1.jpg'
import nasercity2 from '../../Images/nasercity2.jpg'






export default function NaserCity() {
  return (

    <>
        <div className="container my-5 py-5  ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Nasr City Hotel  ​</h3>
                <p> Project description: 10 Floors building   </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  25000 m2  </p>
                {/* <p> Owner Name : Haj Yaseen Al-shuqairi yasinko </p> */}
                <p>Type of works : Architectural  </p>
            </div>
            <div className="col-md-6">
                <img src={nasercity1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row ">
          <div className="col-md-12 my-1 text-center">
               <img src={nasercity2} alt="achegy" className={`img-fluid`}/> 
            </div>
         
           
         
        
          
          </div>

        </div>

    </>
  )
}
