import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import kenana1 from '../../Images/kenana1.jpg'
import kenana2 from '../../Images/kenana2.jpg'
import kenana3 from '../../Images/kenana3.jpg'
import kenana4 from '../../Images/kenana4.jpg'
import kenana5 from '../../Images/kenana5.jpg'
import kenana6 from '../../Images/kenana6.jpg'
import kenana7 from '../../Images/kenana7.jpg'

export default function Kenana() {
  return (
   <>
              <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Kenana factory  </h3>
                <p> Project description: electromechanical work </p>
                <p>Type of contract: design and supervision  </p>
                <p> Area:  9000 m2 </p>
                <p>Owner Name : Kenana </p>
                <p>Type of works : : electromechanical and operating license</p>
            </div>
            <div className="col-md-6">
                <img src={kenana1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={kenana2} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={kenana3} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={kenana4} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={kenana5} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={kenana6} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={kenana7} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>


          </div>
        </div>
   
   </>
  )
}
