import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import pharmfac1 from '../../Images/pharma-factory1.jpg'
import pharmfac2 from '../../Images/pharma-factory5.jpg'
import pharmfac3 from '../../Images/pharma-factory3.jpg'
import pharmfac4 from '../../Images/pharma-factory4.jpg'
import pharmfac5 from '../../Images/pharma-factory2.jpg'
import projects1 from '../../Images/projects1.png'
import cid1 from '../../Images/cid1.jpg'
import escap1 from '../../Images/escap1.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'

export default function Pharma() {
  useEffect(() => {
    AOS.init({duration: 1500})
  
  }, [])
  return (

    <>
       <section className='my-5 py-3'>
       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Pharma Projects
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
        <div className="row">
        <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
           
         <Link to="/nerhado" className={`${styles.linkPro}`}> 
         <img src={pharmfac2} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3 '>Nerhado for medicines-6th October city</p>
         </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
              <Link to="/andalos" className={`${styles.linkPro}`}> 
            <img src={pharmfac5} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>AL-Andalus for medicines–6th October city </p>
             </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/solvida" className={`${styles.linkPro}`}> 
            <img src={pharmfac3} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>SOLVIDA Pharma-10th Ramadan</p>
            </Link> 
          </div>
        </div>
        <div className="row">
        <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
        <Link to="/hekma" className={`${styles.linkPro}`}> 
            <img src={pharmfac1} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Hekma Pharma-6th October city</p>
            </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/riva" className={`${styles.linkPro}`}>  
            <img src={pharmfac4} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '> RIVA Pharma–Nasir City </p>
            </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/eg" className={`${styles.linkPro}`}> 
            <img src={projects1} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '> EG GEL for medicines - 6th October city </p>
            </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/bio" className={`${styles.linkPro}`}> 
            <img src={projects1} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '> Biological products and medicines factory - 6th octover city </p>
            </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/cid" className={`${styles.linkPro}`}> 
            <img src={cid1} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '> cid Pharmaceutical , haram city  </p>
            </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
          <Link to="/escap" className={`${styles.linkPro}`}> 
            <img src={escap1} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '> Ecap  vacsera , agouza , giza </p>
            </Link> 
          </div>
       
        </div>
       </div>
     
    
    </section>

    
    </>
   
  )
}
