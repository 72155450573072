import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import eng1 from '../../Images/eng1.jpg'
import eng2 from '../../Images/eng2.jpg'






export default function Eng() {
  return (

    <>
        <section className="container my-5 py-5  ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}> Engineer Resturant ​</h3>
                <p> Project description: : Restaurant     </p>
                <p>Type of contract: Design and supervision   </p>
                <p> Area:  500 m2  </p>
                <p>Type of works : Decoration   </p>
            </div>
            <div className="col-md-5">
                <img src={eng2} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row ">
          <div className="col-md-12 my-1 text-center">
               <img src={eng1} alt="achegy" className={`img-fluid`}/> 
            </div>
         
           
         
        
          
          </div>

        </section>

    </>
  )
}
