import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import urban2p from '../../Images/urban2.png'
import urban2p1 from '../../Images/urban2.1.jpg'
import urban2p2 from '../../Images/urban2.2.jpg'
import urban2p3 from '../../Images/urban2.3.jpg'
import urban2p4 from '../../Images/urban2.4.jpg'
import urban2p5 from '../../Images/urban2.5.jpg'
import urban2p6 from '../../Images/urban2.6.jpg'



export default function urban2() {
  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`container`}>
        
          <div className='container'>
              <div className="row align-items-center">
                <div className="col-md-6">
                    <h3 className={`${styles.color} my-3`}> Teba Unversity - Ola Branch ​</h3>
                    <p> Project description: Planning Of Unversity  </p>
                    <p>Type of contract: Design  </p>
                    <p> Area:  15 million m2  </p>
                    <p>Owner Name : Teba Unversity </p>
                    <p>Type of works : Master Planning Detailed Planning Landscape Infra-structures </p>
                </div>
                <div className="col-md-6">
                    <img src={urban2p} alt="achegy" className='img-fluid'/>
                </div>   
              </div>
              <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={urban2p1} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={urban2p2} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={urban2p3} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={urban2p4} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={urban2p5} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={urban2p6} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
          
          </div>
          </div>
   
       </div>


    </section>

    
    </>
   
  )
}
