import React from 'react'
import styles from '../Proj3Folder/Proj3.module.css'
import storage2 from '../../Images/storage2.jpg'

export default function Storage() {
  return (
   <>
      <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> storage ​</h3>
                <p> Project description: storage    </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  8000 m2 </p>
                <p>Owner Name : travco Group  </p>
                <p>Type of works : : architectural , structure and electromechanical design</p>
            </div>
            <div className="col-md-6">
                <img src={storage2} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
        </div>
   
   </>
  )
}
