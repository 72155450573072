import React from 'react'
import styles from '../Proj3Folder/Proj3.module.css'
import centralKitchen1 from '../../Images/centralKitchen1.jpg'
import centralKitchen2 from '../../Images/centralKitchen2.jpg'
import centralKitchen3 from '../../Images/centralKitchen3.jpg'


export default function CentralKitchen() {
  return (
   <>
              <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}>Central kitchen​</h3>
                <p> Project description: central kitchen    </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  3000 m2 </p>
                <p>Owner Name : travco Group  </p>
                <p>Type of works : architectural and civil design</p>
            </div>
            <div className="col-md-6">
                <img src={centralKitchen1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5">
          <div className="col-md-6 my-1 text-center">
               <img src={centralKitchen2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-6 my-1 text-center">
               <img src={centralKitchen3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>

    
          </div> 
        </div>
   
   </>
  )
}
