import React from 'react'
import styles from '../Proj3Folder/Proj3.module.css'
import interlock1 from '../../Images/interlock1.jpg'
import interlock2 from '../../Images/interlock2.jpg'
import interlock3 from '../../Images/interlock3.jpg'
import interlock4 from '../../Images/interlock4.jpg'


export default function InterLock() {
  return (
   <>
           <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Interlock industries ​</h3>
                <p> Project description: interlock    </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  3600m2 </p>
                <p>Owner Name : travco Group  </p>
                <p>Type of works : architectural and steel structure design</p>
            </div>
            <div className="col-md-6">
                <img src={interlock2} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5">
          <div className="col-md-4 my-1 text-center">
               <img src={interlock1} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={interlock3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={interlock4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
    
          </div> 
        </div>
   </>
  )
}
