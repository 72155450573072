import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import alunile from '../../Images/sadatfac.jpg'
import amricana from '../../Images/amricana.jpg'
import nilex from '../../Images/nilex1.jpg'
import dabaa from '../../Images/dabaa.jpg'
import najar from '../../Images/najar1.png'
import ifcg1 from '../../Images/ifcg1.jpg'
import rabie1 from '../../Images/rabie1.jpg'
import kenana1 from '../../Images/kenana1.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'

export default function Proj1() {
  useEffect(() => {
    AOS.init({duration: 1500})
  
  }, [])
  return (

    <>
   

<section className='my-5 py-3 '>
       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Industrial Projects 

        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
        <div className="row ">

        <div className="col-md-4 my-3 text-center" data-aos='fade-up'> 
              <Link to="/alunile" className={`${styles.linkPro}`}> 
            <img src={alunile} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'> Alunile Factory  </p>
             </Link> 
          </div>
      
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'> 
              <Link to="/najar" className={`${styles.linkPro}`}> 
            <img src={najar} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'> Najar Factory  </p>
             </Link> 
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
              <Link to="/dabaa" className={`${styles.linkPro}`}> 
            <img src={dabaa} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>El Dabaa Cold Store </p> 
             </Link> 
          </div>

          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>      
            <Link to="/nilex" className={`${styles.linkPro}`}>    
            <img src={nilex} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3'>Nilex Frozen Factory </p>
            </Link> 
          </div>

          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
         <Link to="/americana" className={`${styles.linkPro}`}> 
         <img src={amricana} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'> Americana for food-6th October city </p>
         </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
         <Link to="/ifcg" className={`${styles.linkPro}`}> 
         <img src={ifcg1} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'> IFCG </p>
         </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
         <Link to="/rabie" className={`${styles.linkPro}`}> 
         <img src={rabie1} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'> Rabie al – takyeef factory , 6th of October city </p>
         </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
         <Link to="/kenana" className={`${styles.linkPro}`}> 
         <img src={kenana1} alt="achegy" className={`${styles.imgSizeProj}`}/>
         <p className='text-center fw-bold mt-3'> Kenana factory </p>
         </Link>  
          </div>
         
        </div>
       </div>  
       

    </section> 

    </>
   
  )
}
