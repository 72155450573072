import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import smoha from '../../Images/smoha.jpg'
import smoha1 from '../../Images/smoha1.jpg'





export default function NBEthawra() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> National Bank of Egypt (Al-Ahly)- Smouha Club branch  ​</h3>
                <p> Project description: Development Bank  </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  650 m2  </p>
                <p>Owner Name : National Bank Of Egypt (Al-Ahly) </p>
                <p>Type of works : Decoration and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={smoha} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-12 my-1 text-center">
               <img src={smoha1} alt="achegy" className={`img-fluid`}/> 
            </div>
      
           
         
        
          
          </div>
        </div>

    </>
  )
}
   