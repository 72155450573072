import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import crystal from '../../Images/crystal.jpg'





export default function Crystal() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-8">
                <h3 className={`${styles.color} my-3`}> Cristal Tower – Commercial and Administrative Building 
   ​</h3>
                <p> Project description: Development for revenue billing floor   </p>
                <p>Type of contract: Design </p>
                <p> Area:  20000 m2  </p>
                <p>Owner : Mohammed Abdel monem Saudi company from his companies</p>
                <p>Type of works : Architectural  </p>
            </div>
            <div className="col-md-4 text-center">
                <img src={crystal} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

        </div>

    </>
  )
}
