import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import planning2 from '../../Images/planning2.jpg'
import urban1p1 from '../../Images/urban1.1.png'
import urban1p2 from '../../Images/urban1.2.png'
import urban1p3 from '../../Images/urban1.3.png'
import urban1p4 from '../../Images/urban1.4.png'
import urban1p5 from '../../Images/urban1.5.png'
import urban1p6 from '../../Images/urban1.6.png'
import urban1p7 from '../../Images/urban1.7.png'
import urban1p8 from '../../Images/urban1.8.png'
import urban1p9 from '../../Images/urban1.9.png'
import urban1p10 from '../../Images/urban1.10.png'
import urban1p11 from '../../Images/urban1.11.png'
import urban1p12 from '../../Images/urban1.12.png'



export default function urban1() {
  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`container`}>
        
          <div className=" my-5 py-5 ">
              <div className="row align-items-center">
                <div className="col-md-6">
                    <h3 className={`${styles.color} my-3`}> Teba Unversity - Yanbu Branch ​</h3>
                    <p> Project description: Planning Of Unversity  </p>
                    <p>Type of contract: Design  </p>
                    <p> Area:  10 million m2  </p>
                    <p>Owner Name : Teba Unversity </p>
                    <p>Type of works : Master Planning Detailed Planning Landscape Infra-structures </p>
                </div>
                <div className="col-md-6">
                    <img src={planning2} alt="achegy" className='img-fluid'/>
                </div>   
              </div>
              <div className="row my-5">
          <div className="col-md-4 my-1 text-center">
               <img src={urban1p1} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p2} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p3} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p4} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p5} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p6} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p7} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p8} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p9} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p10} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p11} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={urban1p12} alt="achegy" className={`${styles.imgSizeProjUrban}`}/> 
            </div>
          </div>
          </div>
   
       </div>


    </section>

    
    </>
   
  )
}
