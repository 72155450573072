import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import urban10p from '../../Images/urban10.jpg'



export default function urban10() {
  return (

    <>
       <section className='my-5 py-3 '>
       <div  className={`container`}>
        
          <div className="container my-5 py-5 ">
              <div className="row align-items-center">
                <div className="col-md-6">
                    <h3 className={`${styles.color} my-3`}> Evergreen Compound ​</h3>
                    <p> Client: Dr. Abdelsalam Salem , Eng. Mohamed Talaat  </p>
                    <p>Location : Cairo - Alex desert road  </p>
                    <p>Services Provided : Project Management Intergrated Documents , Site Supervision  </p>
                </div>
                <div className="col-md-6">
                    <img src={urban10p} alt="achegy" className='img-fluid'/>
                </div>   
              </div>

          </div>
   
       </div>


    </section>

    
    </>
   
  )
}
