import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import zarqa from '../../Images/zarqa.jpg'





export default function NBEthawra() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-7">
                <h3 className={`${styles.color} my-3`}> National Bank of Egypt (Al-Ahly)- Zarqa in Damietta branch  ​</h3>
                <p> Project description: Development of residential and management section </p>
                <p>Type of contract: Design and supervision </p>
                <p> Area:  1000 m2  </p>
                <p>Owner Name : National Bank Of Egypt (Al-Ahly) </p>
                <p>Type of works : Decoration and Electromechanical </p>
            </div>
            <div className="col-md-5 text-center">
                <img src={zarqa} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

        </div>

    </>
  )
}
