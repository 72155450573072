import React from 'react'
import styles from '../Proj1Folder/Proj1.module.css'
import cid1 from '../../Images/cid1.jpg'
import cid2 from '../../Images/cid2.jpg'

export default function Cid() {
  return (
    <>
        <div className="container my-5 py-5 ">
          <div className="row  align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> cid </h3>
                <p> Project description: electromechanical work </p>
                <p>Type of contract: design and supervision  </p>
                <p> Area:  10000 m2 </p>
                <p>Owner Name : cid </p>
                <p>Type of works : : electromechanical design</p>
            </div>
            <div className="col-md-6">
                <img src={cid1} alt="achegy" className='img-fluid'/>
            </div>   
          </div>

          <div className="row my-5 pb-5">
          <div className="col-md-12 my-1 text-center">
               <img src={cid2} alt="achegy" className={`${styles.imgSizeProj1}`}/> 
            </div>


          </div>
        </div>

    </>
  )
}
